<template>
<div>
  <!-- BOC:[error] -->
  <AErrorV1 :api="api"></AErrorV1>
  <!-- EOC -->
  <!-- BOC:[form] -->
  <v-form
    ref="form"
    lazy-validation
    @submit.prevent="validate"
  >
    <v-card>
      <v-card-title>{{(action == 'add') ? $t("string.bread_new"): ((action == 'assign') ? $t("string.bread_assign") : action == 'approve' ? 'Approve' : $t("action.edit")) }}{{($_getLocale() == 'zh')?'':' '}}{{$t("model.name." + this.model.name.singular.toLowerCase())}}</v-card-title>
      <v-card-text>
        <template v-for="field in fields">
          <template v-if="field.type == 'model'">
            <BreadFormSelectModel
              :value="form[field.key]" 
              @input="form[field.key] = $event"
              :key="field.key"
              :field="field"
              :url="(role.toLowerCase() == 'admin') ? $api.servers[role.toLowerCase()]+'/v1/bread/'+field.model+'?lang='+$_getLocale() : $api.servers[field.server]+'/api/v1/'+$_getLocale()+'/data/'+field.model "
              :rules="(rules[field.key]) ? rules[field.key] : []"
              :role="role"
            ></BreadFormSelectModel>
          </template>
          <template v-else-if="field.type == 'select'">
            <v-select
            v-if="field.key == 'type'"
              v-model="type"
              :key="field.key + 'type'"
              :label="field.name"
              :items="field.options"
              :item-text="field.optionText"
              :item-value="field.optionValue"
            ></v-select>
            <v-select
            v-else
              :disabled="model.key == 'User' && type == 'adult' "
              v-model="form[field.key]"
              :key="field.key"
              :label="field.name"
              :items="field.options"
              :item-text="field.optionText"
              :item-value="field.optionValue"
            ></v-select>
          </template>
          <template v-else-if="field.type == 'boolean'">
            <v-select
              v-model="form[field.key]"
              :key="field.key"
              :label="field.name"
              :items="[{label:'Yes',value:true},{label:'No',value:false}]"
              item-text="label"
              item-value="value"
            ></v-select>
          </template>
          <template v-else-if="field.type == 'date'">
            <BreadFormDate
              :value="form[field.key]" 
              @input="form[field.key] = $event"
              :key="field.key"
              :field="field"
            ></BreadFormDate>
          </template>
          <template v-else-if="field.type == 'time'">
            <BreadFormTime
              :value="form[field.key]" 
              @input="form[field.key] = $event"
              :key="field.key"
              :field="field"
            ></BreadFormTime>
          </template>
           <template v-else-if="field.type == 'radio'">
            <BreadFormRadio
              :value="form[field.key]" 
              @input="form[field.key] = $event"
              :key="field.key"
              :field="field"
            ></BreadFormRadio>
          </template>
          <template v-else>
            <v-text-field
               v-if="field.key == 'name'"
              v-model="form[field.key]"
              :type="field.type"
              :key="field.key"
              :label="field.name"
              :max="field.max ? field.max : false"
              :min="field.min ? field.min : false"
              :rules="(rules[field.key]) ? rules[field.key] : []"
            ></v-text-field>
            <v-text-field
               v-if="field.key != 'name'"
              :disabled="model.key == 'User' && type == 'adult' "
              v-model="form[field.key]"
              :type="field.type"
              :key="field.key"
              :label="field.name"
              :max="field.max ? field.max : false"
              :min="field.min ? field.min : false"
              :rules="(rules[field.key]) ? rules[field.key] : []"
            ></v-text-field>
          </template>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="$router.go(-1)"
        >
         {{$t("action.cancel")}}
        </v-btn>
        <v-btn
          color="primary"
          :loading="api.isLoading"
          @click="validate"
        >
        {{$t("action.confirm")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
  <!-- EOC -->
</div>
</template>

<script>
  import BreadFormDate from '@/components/Bread/BreadFormDate'
  import BreadFormTime from '@/components/Bread/BreadFormTime'
  import BreadFormSelectModel from '@/components/Bread/BreadFormSelectModel'
  import BreadFormRadio from '@/components/Bread/BreadFormRadio'
  import { mapState } from 'vuex'
  export default {
    components:{
      BreadFormDate,
      BreadFormTime,
      BreadFormSelectModel,
      BreadFormRadio,
    },
    watch: {
    type: function () {
      this.form['type'] = this.type
    },
  },
    computed: mapState({
      //
    }),
    props:[
      'self',
      'role',
      'model',
      'url',
      'action',
      'callbackSuccess',
    ],
    data: () => ({
      //BOC:[api]
      api:{
        isLoading:false,
        isError:false,
        error:null,
        url:null,
      },
      //EOC
      //BOC:[form]
      form: {},
      fields:[],
      rules:{},
      //EOC
      type:'child',
    }),
    created() {
      //BOC:[fields]
      if(this.action == 'edit' && this.model[this.role][this.action] == true) {
        this.fields = this.model[this.role].add.fields
        this.rules = this.model[this.role].add.rules
      }
      else {
        this.fields = this.model[this.role][this.action].fields
        this.rules = this.model[this.role][this.action].rules
      }
      //EOC
      //BOC:[form]
      
      this.fields.forEach((field)=>{
        if(this.action == 'edit' || this.action == 'approve' ) {
          if(field.type == "model" && this.self[this.$_ucfirst(field.model)]) {
            if(field.optionValue && field.optionValue == "key"){
            this.form[field.key] = this.self[this.$_ucfirst(field.model)].key
            }else{
              this.form[field.key] = this.self[this.$_ucfirst(field.model)].id
            }
          }
          else {
            this.form[field.key] = this.self[field.key]
            if(field.key=='type'){
              this.type = this.self[field.key]
            }
          }
        }
        else {
          this.form[field.key] = (typeof field.defaultValue != 'undefined') ? field.defaultValue : null
        }
      })
      //
      if(this.action == 'edit' || this.action == 'approve' || this.action == 'assign') {
        this.form.id = this.$route.params.id
      }
      //EOC
      //BOC:[api]
      this.api.url = this.url
      this.api.callbackReset = () => {
        this.api.isLoading = true
        this.api.isError = false
        this.api.error = null
      }
      this.api.callbackError = (e) => {
        this.api.isLoading = false
        this.api.isError = true
        this.api.error = e
      }
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false
        this.callbackSuccess(resp)
      }
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      //BOC:[form]
      validate() {
        this.api.callbackReset()
        if(this.$refs.form.validate()) {
          this.submit()
        }
        else {
          this.api.callbackError('Please check the form.')
        }
      },
      submit() {
        this.api.params = this.$_.clone(this.form)
        this.$api.fetch(this.api)
      },
      //EOC
    },
  }
</script>